import axios from "axios";
import { agencyAPI } from "@/modules/api-config";
import { reportError } from "@/utils/error-reporting";
import { downloadBlobFromResponse } from "@/utils/url";

async function getArchiveRents({ queries = {}, page = 1, perPage = 30 }) {
    try {
        const include = [
            "property",
            "tenancyAgreement.landLordDetail.users",
            "tenancyAgreement.tenantDetail.users"
        ];
        const URL = `${agencyAPI}/property-rentals/archives`;

        let response = await axios.get(URL, {
            params: {
                ...queries,
                page: page,
                perPage: perPage,
                include: include.join(",")
            }
        });

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get archive rents`);
        throw error;
    }
}

async function getArchiveRent(id) {
    const include = [
        "property",
        "tenancyAgreement.landLordDetail.users",
        "tenancyAgreement.tenantDetail.users"
    ];
    const URL = `${agencyAPI}/property-rentals/archives/${id}`;

    try {
        let response = await axios.get(`${URL}?include=${include.join(",")}`);
        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Get archive rent (${id})`);
        throw error;
    }
}

async function downloadTenancyAgreement(id) {
    const URL = `${agencyAPI}/property-rentals/archives/${id}/tenancy-agreement/export`;
    try {
        let response = await fetch(URL, {
            headers: new Headers({
                Authorization:
                    "Bearer " + localStorage.getItem("auth_tophillsagency_key"),
                "Content-Type": "application/x-www-form-urlencoded"
            }),
            method: "POST"
        });
        let fileName = response.headers
            .get("content-disposition")
            .split("filename=")[1]
            .replace(/^"|"$/g, "");
        downloadBlobFromResponse(response, fileName);
    } catch (error) {
        reportError(error, `[Agency CP] Download TA (ID: ${id})`);
        throw error;
    }
}

async function createArchiveRent(payload) {
    try {
        const URL = `${agencyAPI}/property-rentals/archives`;
        let response = await axios.post(URL, payload);

        return response.data;
    } catch (error) {
        reportError(
            error,
            `[Agency CP] Create archive rent (property: ${payload.propertyId})`
        );
        throw error;
    }
}

async function updateArchiveRent(id, payload) {
    try {
        const URL = `${agencyAPI}/property-rentals/archives/${id}`;
        let response = await axios.put(URL, payload);

        return response.data;
    } catch (error) {
        reportError(error, `[Agency CP] Update archive rent (${id})`);
        throw error;
    }
}

export {
    getArchiveRents,
    getArchiveRent,
    downloadTenancyAgreement,
    createArchiveRent,
    updateArchiveRent
};
