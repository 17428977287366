<template>
  <div class="edit-archived-rent">
    <div class="container fluid">
      <h1 class="my-4 mx-2">Edit Archived Rent</h1>

      <div class="card p-3 mb-2">
        <PropertySummary :property="property" address></PropertySummary>
      </div>
      <div class="card p-3">
        <archive-rent-form-content
          v-if="archivedRentContent"
          isEdit
          :key="formKey"
          ref="archiveRentForm"
          :propertyId="property.id"
          :content="archivedRentContent"
          :isLoading.sync="isLoading"
          @validated="archiveRent"
          :stickyOffset="70"
        ></archive-rent-form-content>
      </div>

      <fd-button
        class="fixed round main medium"
        loadingEnabled
        :isLoading="isLoading"
        icon="fas fa-edit"
        @click="triggerSubmit"
        >Update
      </fd-button>
    </div>
  </div>
</template>

<script>
import {
  getArchiveRent,
  updateArchiveRent
} from "@/modules/Rent/api/archiveRent";
import { RentTAModel } from "@/models";

export default {
  components: {
    PropertySummary: () =>
      import("@/components/GlobalComponents/Shared/Property/PropertySummary"),
    ArchiveRentFormContent: () =>
      import("@/modules/Rent/components/ArchiveRent/ArchiveRentFormContent")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      archivedRentContent: null,
      property: {},
      isLoading: false,
      formKey: 0
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getArchivedRent();
    },
    async getArchivedRent() {
      try {
        this.$store.commit("setIsLoading", true);

        let data = await getArchiveRent(this.$route.params.id);
        this.archivedRentContent = RentTAModel.getToEditArchiveRent(
          data.tenancyAgreement
        );
        this.property = data.property;
        this.formKey++;

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch archived rent. Please try again later."
        });
      }
    },
    triggerSubmit() {
      this.$refs.archiveRentForm.submit();
    },
    async archiveRent(archiveRentContent) {
      try {
        this.$store.commit("setIsLoading", true);
        let payload = RentTAModel.toUpdateArchiveRent(archiveRentContent);
        console.log(archiveRentContent);

        await updateArchiveRent(this.$route.params.id, payload);
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Archive For Rent",
          text: "Property has been archived for rent purpose."
        });

        this.getArchivedRent();
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Archive For Rent",
          text:
            "Failed to archive property for rent purpose. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
